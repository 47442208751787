import {MEMBERS_INFO_APP_ID, MEMBERS_INFO_SECTION_ID, ExperimentNames} from '@wix/wix-events-commons-statics'
import {createAsyncAction} from '../services/redux-toolkit'
import {isMembersInstalled} from '../../../../commons/utils/members-api'

export const checkMembersAreaInstallation = createAsyncAction<boolean>(
  'CHECK_MEMBERS_AREA_INSTALLATION',
  (
    _,
    {
      extra: {
        controllerParams: {
          flowAPI,
          controllerConfig: {wixCodeApi},
        },
      },
    },
  ) =>
    flowAPI.experiments.enabled(ExperimentNames.EventsMembersAreaV2)
      ? isMembersInstalled(wixCodeApi)
      : wixCodeApi.site.isAppSectionInstalled({
          sectionId: MEMBERS_INFO_SECTION_ID,
          appDefinitionId: MEMBERS_INFO_APP_ID,
        }),
)

export type CheckMembersAreaInstallation = typeof checkMembersAreaInstallation
